import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import classes from './TeamTimesheet.module.scss';
import RegManagementList from './regManagementList/RegManagementList';
import FilterTimeRegistration from './filterTimeRegistration/FilterTimeRegistration';
import {
  getTeamRegistrations,
  getProjectByTeam,
} from '../service/TeamTimesheetService';
import { dateFormats } from '../../../../utilities/dateUtils';
import { getListOfActiveMembers } from '../../../orgmanagement/service/orgmanagementService';
import { getRegistrations } from '../../../timeregistration/service/timeregistrationService';
import { userRoles } from '../../../../utilities/roleUtils';
import { projectStatusName } from '../../../../utilities/constantUtils';
import { format } from 'date-fns';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const TeamTimesheet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);
  const [startDate, setStartDate] = useState(
    dayjs().startOf('week').add(1, 'day'),
  );
  const userProfileId = useSelector((state) => state.userContext.userId);
  const [endDate, setEndDate] = useState(dayjs().startOf('week').add(7, 'day'));
  const [employee, setEmployee] = useState(t('we-timesheet.all-employees'));
  const [project, setProjects] = useState(t('we-timesheet.all-projects'));
  const [team, setTeams] = useState(t('we-timesheet.all-teams'));
  const teamRegistrations = useSelector(
    (state) => state.teamTimesheetContext.teamRegistrations,
  );
  const orgList = useSelector((state) => state.userContext.userOrganizations);
  const toolBarshow = useSelector((state) => state.toolBarContext.toolBarShow);
  const teamList = useSelector((state) => state.orgContext.teams);

  const [selectedOrg, setSelectedOrg] = React.useState(undefined);
  const [listData, setListData] = useState([]);

  const mainOrgProjList = useSelector((state) => state.projectContext.projects);
  const organizations = useSelector(
    (state) => state.userContext.userOrganizations,
  );

  const [mainProjectList, setMainProjectList] = useState([]);

  const [projectList, setProjectList] = useState([]);

  useEffect(() => {
    if (mainOrgProjList.length > 0) {
      const mappedOrgId = orgList.map((org) => {
        return org?.id;
      });
      let checkAdmin = false;
      organizations?.forEach((org) => {
        var isAdmin = org?.userAuth?.find(
          (auth) => auth === userRoles?.orgAdmin,
        );
        if (isAdmin) {
          checkAdmin = true;
        }
      });

      let nMainOrgProjList = mainOrgProjList?.filter(
        (proj) => proj?.projectOrganizationId === mappedOrgId?.[0],
      );

      if (team?.value) {
        if (!team?.label.includes('Org-wide')) {
          nMainOrgProjList = nMainOrgProjList?.filter(
            (proj) => proj?.ownerId === team?.value,
          );
        } else {
          nMainOrgProjList = nMainOrgProjList?.filter(
            (proj) => proj?.projectOrganizationId === team?.value,
          );
        }
      }

      let filterTeams = teamList?.filter(
        (team) => team?.teamManager?.userId === userProfileId,
      );

      const projects = [];
      nMainOrgProjList?.forEach((proj) => {
        let isProjectActive = true;
        if (
          proj?.projectStatus === projectStatusName.cancelled ||
          proj?.projectStatus === projectStatusName.completed ||
          proj?.projectStatus === projectStatusName.draft ||
          proj?.projectStatus === projectStatusName.paused
        ) {
          isProjectActive = false;
        }
        const isProject = filterTeams?.find(
          (team) => team?.teamId === proj?.ownerId,
        );
        if ((isProject || checkAdmin) && isProjectActive) {
          projects.push({
            label: proj?.projectName,
            value: proj?.projectId,
            owner: proj?.ownerId,
          });
        }
      });
      setMainProjectList(projects);
      setProjectList([
        {
          label: t('we-timesheet.all-projects'),
          value: t('we-timesheet.all-projects'),
        },
        ...projects,
      ]);
    }
  }, [mainOrgProjList, orgList, team, teamList]);

  useEffect(() => {
    const nTeamRegistrations = [];
    teamRegistrations.forEach((reg) => {
      const exist = mainProjectList.find(
        (proj) => proj.value === reg.description.id,
      );
      if (exist) {
        nTeamRegistrations.push(reg);
      }
    });
    setListData(nTeamRegistrations);
    if (project?.label !== undefined) {
      if (project?.label !== t('we-timesheet.all-projects')) {
        setListData(
          teamRegistrations.filter(
            (teamReg) =>
              teamReg.description.id.toString() === project.value.toString(),
          ),
        );
      }
    }
  }, [teamRegistrations, mainProjectList]);

  useEffect(() => {
    if (orgList?.length > 0) {
      setSelectedOrg(orgList?.[0]);
      loadActiveMembers(orgList?.[0]?.emp);
    }
  }, [orgList]);

  useEffect(() => {
    getProjectList();
  }, []);

  const loadActiveMembers = (id) => {
    dispatch(getListOfActiveMembers(id));
  };
  const getProjectList = () => {
    let regObj = {
      from: format(new Date(startDate), dateFormats.yyyymmdd),
      to: format(new Date(endDate), dateFormats.yyyymmdd),
    };
    dispatch(getRegistrations(regObj));
  };

  const getProjectListByTeam = (id) => {
    let regObj = {
      from: format(new Date(startDate), dateFormats.yyyymmdd),
      to: format(new Date(endDate), dateFormats.yyyymmdd),
      team: id,
    };
    dispatch(getProjectByTeam(regObj));
  };

  const getTeamRegList = (from, to, employee, project, team) => {
    const params = {
      from: format(new Date(from), dateFormats.yyyymmdd),
      to: format(new Date(to), dateFormats.yyyymmdd),
      employee: employee,
      project: project,
      team: team,
    };
    setLoader(true);
    dispatch(getTeamRegistrations(params));
    setLoader(false);
  };

  useEffect(() => {
    const employeeParam =
      employee === t('we-timesheet.all-employees') ? '' : employee;
    const projectParam =
      project === t('we-timesheet.all-projects') ? '' : project;
    const teamParam = team === t('we-timesheet.all-teams') ? '' : team;
    getTeamRegList(startDate, endDate, employeeParam, projectParam, teamParam);
  }, []);

  const getDate = (value, field) => {
    if (value == null) return;
    if (!isNaN(value)) {
      var date = moment(new Date(value)).format(
        dateFormats.yyyymmdd.toUpperCase(),
      );
      if (moment(date).isValid()) {
        if (field === 'dateFrom') {
          setStartDate(value);
          if (value > endDate) {
            setEndDate(value);
            getTeamRegList(startDate, value, employee?.value, project, team);
          } else {
            setStartDate(value);
            getTeamRegList(value, endDate, employee?.value, project, team);
          }
        } else if (field === 'dateTo') {
          if (value < startDate) {
            setEndDate(startDate);
            getTeamRegList(
              startDate,
              startDate,
              employee?.value,
              project,
              team,
            );
          } else {
            setEndDate(value);
            getTeamRegList(startDate, value, employee?.value, project, team);
          }
        }
      }
    }
  };

  const onChangeTeamOrProjectHandler = (team) => {
    if (team) {
      var selected = '';
      const hasSelectedTeam = !team?.label.includes('Org-wide');
      if (hasSelectedTeam && team.value !== 0) {
        selected = team?.value;
        getTeamRegList(startDate, endDate, '', '', selected);
        getProjectListByTeam(selected);
        loadActiveMembers(selected);
      } else {
        getTeamRegList(startDate, endDate, '', '', selected);
        getProjectList();
        loadActiveMembers(selectedOrg.emp);
      }
    }
  };

  const onSelectEmployee = (_, employeeObject) => {
    setEmployee(employeeObject);
    if (employeeObject) {
      var selected = '';
      if (employeeObject?.value !== t('we-timesheet.all-employees')) {
        selected = employeeObject?.value;
      }
      getTeamRegList(startDate, endDate, selected, project?.value, team?.value);
    }
  };
  const onSelectProjects = (_, projectObject) => {
    setProjects(projectObject);
    if (projectObject) {
      const isProjectValueValid = typeof projectObject?.value === 'number';
      if (isProjectValueValid) {
        loadActiveMembers(projectObject?.value);
        getTeamRegList(
          startDate,
          endDate,
          employee,
          projectObject?.value,
          team?.value,
        );
      } else {
        onChangeTeamOrProjectHandler(team);
      }
      setEmployee(t('we-timesheet.all-employees'));
    }
  };

  const onSelectTeams = (_, teamObject) => {
    setTeams(teamObject);
    onChangeTeamOrProjectHandler(teamObject);
    setProjects(t('we-timesheet.all-projects'));
    setEmployee(t('we-timesheet.all-employees'));
  };

  const onRefeshGetTeamRegList = () => {
    getTeamRegList(startDate, endDate, employee, project, team);
  };

  return (
    <div>
      {!toolBarshow && (
        <>
          {loader && (
            <Box
              sx={{ width: '100%' }}
              className={`LinearProgress ${classes.box}`}
            >
              <LinearProgress />
            </Box>
          )}
        </>
      )}

      <div className={classes.weTimesheet_card}>
        <div className={classes.weTimesheet_header_con}>
          <div className={classes.titleWidth}>
            <Typography className={classes.weTimesheet_name}>
              {t('we-timesheet.time-registration-management')}
            </Typography>
            <Typography className={classes.weTimesheet_desc}>
              {t(
                'we-timesheet.approve-decline-or-open-for-editing-multiple-time-registrations-of-your-employees',
              )}
            </Typography>
          </div>
        </div>
        <div className={classes.weTimesheet_filter}></div>
        <div className={classes.padd}>
          <Divider />
          <FilterTimeRegistration
            startDate={startDate}
            endDate={endDate}
            employee={employee}
            project={project}
            team={team}
            getDate={getDate}
            onSelectEmployee={onSelectEmployee}
            onSelectProjects={onSelectProjects}
            onSelectTeams={onSelectTeams}
            projectList={projectList}
          />
          <RegManagementList
            regTeamList={listData}
            onRefeshGetTeamRegList={onRefeshGetTeamRegList}
          />
        </div>
      </div>
    </div>
  );
};
export default TeamTimesheet;
