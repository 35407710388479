import React, { useState } from 'react';
import classes from './ProjectList.module.scss';
import { styled } from '@mui/material/styles';
import { projectStatusName } from '../../../../../../../utilities/constantUtils';
import Typography from '@mui/material/Typography';
import {
  Grid,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Fade,
} from '@mui/material';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import moment from 'moment';
import { dateFormats } from '../../../../../../../utilities/dateUtils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';

const DataGridContainer = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-toolbarContainer': {
    borderBottom: '1px solid #e9e9e9',
  },
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'hidden',
  },
  '& .MuiDataGrid-row:hover svg': {
    visibility: 'visible !important',
    cursor: 'pointer',
  },
  '& .MuiDataGrid-cell:nth-of-type(1)': {
    position: 'relative',
  },
}));

const ProjectList = (props) => {
  const { t } = useTranslation();
  const { list, selectedOrgId } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openViewMore, setOpenViewMore] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState();

  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };

  const onClickProjectName = (item) => {
    props.onManageProject(item);
    handleClose();
  };

  const columnsWithoutStatus = [
    {
      field: 'name',
      headerName: t('organization.projects'),
      minWidth: 180,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            {/* <div className={classes.pad}>{item?.row.name}</div> */}
            <div
              className={`${classes.pad} ${classes.project_name_hyperlink_div}`}
              onClick={(e) => {
                onClickProjectName(item.row);
              }}
            >
              {item?.row.name}
            </div>

            <div className={classes.task_menu_button_container}>
              <IconButton
                className={classes.task_menu_button}
                onClick={(e) => handleClick(e, item.row)}
              >
                <MoreVertIcon
                  className={`${!open && classes.task_menu_button}`}
                  aria-label="reg menu"
                  aria-controls={open ? 'reg-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                />
              </IconButton>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'ownerName',
      headerName: t('organization.team'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        const isTeamOrgWide = selectedOrgId === item?.row?.ownerDetails?.id;
        return (
          <React.Fragment>
            {isTeamOrgWide ? 'Org-Wide' : item?.row?.ownerName}
          </React.Fragment>
        );
      },
    },
    {
      field: 'tasks',
      headerName: t('organization.tasks'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        var label = t('organization.on-going');
        if (
          item.row.status === projectStatusName.cancelled ||
          item.row.status === projectStatusName.paused
        ) {
          label =
            item.row.task.length > 1
              ? t('organization.tasks')
              : t('organization.task');
        }
        return (
          <React.Fragment>
            {item?.row.task.length} {label}
          </React.Fragment>
        );
      },
    },
    {
      field: 'duration',
      headerName: t('organization.duration'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.pad}>
              {item?.row?.start
                ? moment(item?.row?.start).format(dateFormats.moment_datepicker)
                : ''}{' '}
              {item?.row?.end ? ` ${t('common.to')} ` : ''}
              {item?.row?.end
                ? moment(item?.row?.end).format(dateFormats.moment_datepicker)
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'created_by',
      headerName: t('organization.created-by'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {item?.row?.createdby
                ? item?.row?.createdby?.name
                  ? item?.row?.createdby?.name
                  : ''
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'approver',
      headerName: t('organization.approver'),
      minWidth: 200,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {item?.row?.approver
                ? item?.row?.approver.name
                  ? item?.row?.approver?.name !== undefined
                    ? item?.row?.approver?.name
                    : ''
                  : ''
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
  ];

  const columnsWithStatus = [
    {
      field: 'name',
      headerName: t('organization.projects'),
      minWidth: 180,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.pad}>{item?.row.name}</div>
            <div className={classes.task_menu_button_container}>
              <IconButton
                className={classes.task_menu_button}
                onClick={(e) => handleClick(e, item.row)}
              >
                <MoreVertIcon
                  className={`${!open && classes.task_menu_button}`}
                  aria-label="reg menu"
                  aria-controls={open ? 'reg-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup="true"
                />
              </IconButton>
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'status',
      headerName: t('organization.status'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className="capitalize">{item?.row?.statusDescrition}</div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'ownerName',
      headerName: t('organization.team'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        const isTeamOrgWide = selectedOrgId === item?.row?.ownerDetails?.id;
        return (
          <React.Fragment>
            {isTeamOrgWide ? 'Org-Wide' : item?.row?.ownerName}
          </React.Fragment>
        );
      },
    },
    {
      field: 'tasks',
      headerName: t('organization.tasks'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        var label = t('organization.on-going');
        if (
          item.row.status === projectStatusName.cancelled ||
          item.row.status === projectStatusName.paused
        ) {
          label = item.row.task.length > 1 ? 'tasks' : 'task';
        }
        return (
          <React.Fragment>
            {item?.row.task.length} {label}
          </React.Fragment>
        );
      },
    },
    {
      field: 'duration',
      headerName: t('organization.duration'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div className={classes.pad}>
              {item?.row?.start
                ? moment(item?.row?.start).format(dateFormats.moment_datepicker)
                : ''}{' '}
              {item?.row?.end ? ` ${t('common.to')} ` : ''}
              {item?.row?.end
                ? moment(item?.row?.end).format(dateFormats.moment_datepicker)
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'created_by',
      headerName: t('organization.created-by'),
      minWidth: 150,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {item?.row?.createdby
                ? item?.row?.createdby?.name
                  ? item?.row?.createdby?.name
                  : ''
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
    {
      field: 'approver',
      headerName: t('organization.approver'),
      minWidth: 200,
      flex: 1,
      renderCell: (item) => {
        return (
          <React.Fragment>
            <div>
              {item?.row?.approver
                ? item?.row?.approver.name
                  ? item?.row?.approver?.name !== undefined
                    ? item?.row?.approver?.name
                    : ''
                  : ''
                : ''}
            </div>
          </React.Fragment>
        );
      },
    },
  ];
  const columns = props.tab ? columnsWithoutStatus : columnsWithStatus;

  const ProjectActions = () => {
    return (
      <GridToolbarContainer className={classes.toolBarContainer}>
        <Grid container>
          <GridToolbarFilterButton />
        </Grid>
      </GridToolbarContainer>
    );
  };

  const handleClose = () => {
    setOpenViewMore(false);
    setAnchorEl(null);
  };
  const onDeleteHandler = () => {
    props.onDelete(selectedItem);
    handleClose();
  };

  const onManageProjectHandler = () => {
    props.onManageProject(selectedItem);
    handleClose();
  };

  return (
    <div className={classes.container}>
      <Menu
        className="menuReg"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem
          className={classes.menu_cell_icon}
          onClick={onManageProjectHandler}
        >
          <EditIcon className={classes.menu_icon} />
          {t('organization.manage-project')}
        </MenuItem>

        <MenuItem onClick={() => onDeleteHandler()}>
          <DeleteForeverIcon className={classes.menu_icon} />
          {t('common.delete')}
        </MenuItem>
      </Menu>
      <div
        className={classes.filter_tab}
        style={{ height: 400, width: '100%' }}
      >
        <DataGridContainer
          autoHeight={true}
          columns={columns}
          rows={list}
          getRowHeight={() => 'auto'}
          components={{
            Toolbar: ProjectActions,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } },
          }}
          pageSizeOptions={[10, 20, 100]}
          sx={{
            '.MuiTablePagination-root': {
              display: 'flex',
              justifyContent: 'center',
            },
          }}
          localeText={{
            toolbarFilters: t('organization.filters'),
          }}
        />

        {props.loader && (
          <div
            className={
              classes.box + ' ' + classes.stackTop + ' ' + classes.box2
            }
          >
            <div className={classes.center}>
              <CircularProgress />
              <Typography>{t('organization.getting-project-list')}</Typography>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectList;
